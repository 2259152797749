<template>
  <s-dialog to="early-access-editor-fullscreen" :open="true">
    <s-dialog-panel :id="uuid" class="!w-full !h-full !max-h-[initial] !rounded-none">
      <div
        class="flex h-full w-full flex-col bg-background-variant-2 p-32 dark:bg-surface-variant-1"
        :class="checkedTheme ? 'dark' : ''"
      >
        <div class="mb-16 flex shrink-0 items-center gap-16">
          <h2 class="text-xl font-bold leading-lg text-on-surface-elevation-1">
            {{ title ?? $t('studio.prj_prod.this_prod.home_early_access_title') }}
          </h2>
          <div class="ml-auto flex shrink-0 items-center gap-16">
            <s-switch
              id="checkedTheme"
              v-model="checkedTheme"
              size="sm"
              :labelText="$t('studio.prj_prod.this_prod.edit_summary_darkmode_btn')"
              class="studio-switch ml-auto inline-flex shrink-0 dark:[&>*>span]:text-on-surface-elevation-1"
              isLabelVisible
            />
            <s-button
              size="xs"
              variant="outline"
              icon="ic-v2-media-av-fullscreen-exit-fill"
              :isDisabled="isCodeView"
              @click="handleCloseDialog"
            >
              {{ $t('studio.common.exit_full_scr_btn') }}
            </s-button>
          </div>
        </div>
        <!-- 에디터 -->
        <text-editor
          v-model="textContent"
          name="textContent"
          :toolbarButtons="toolbarButtons"
          :config="editorConfig"
          :charCounterMax="wordCountLimit"
          class="h-[calc(100%-40px)]"
          :disabled="!isProductWritable || isDisabled"
          :rules="{
            required: true
          }"
          :showError="false"
          :projectId="projectId"
          :isChooseOneFileFromStorage="isChooseOneFileFromStorage"
          @onClickBtnHtmlCodeBlock="isCodeView = $event"
          @update:modelValue="handleChange"
        />
      </div>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="early-access-editor-fullscreen" />
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm } from 'vee-validate';
import { onMounted, ref, toRefs } from 'vue';

import TextEditor from '@/components/validation/text-editor.vue';
import useProductStore from '@/stores/product.store';
import { generateUUID } from '@/utils/uuid.util';

const productStore = useProductStore();
const { isProductWritable } = storeToRefs(productStore);

useHead({
  bodyAttrs: {
    class: 'overflow-hidden'
  }
});

const props = defineProps<{
  content: string;
  wordCountLimit: number;
  toolbarButtons: string[];
  projectId?: string;
  title?: string;
  isChooseOneFileFromStorage?: boolean;
  isDisabled?: boolean;
}>();

const emits = defineEmits<{
  close: [
    v: {
      valueRaw: string;
      valueHtml: string;
    }
  ];
}>();

const { setValues } = useForm({
  initialValues: {
    textContent: ''
  }
});

const editorConfig = {
  attribution: false,
  charCounterCount: true,
  wordCounterCount: false,
  placeholderText: '',
  height: '50rem'
};

const { content } = toRefs(props);
const checkedTheme = ref<boolean>(false);
const textContent = ref<string>(content.value);
const formContentTextValue = useFieldValue<string>('textContent');
const isChange = ref<boolean>(false);
const isCodeView = ref<boolean>(false);

const uuid: string = `stds-dialog-${generateUUID()}`;

const handleCloseDialog = () => {
  emits('close', formatDataWhenClose());
};

const handleChange = () => {
  isChange.value = true;
};

const formatDataWhenClose = () => {
  return {
    valueRaw: formContentTextValue.value,
    valueHtml: textContent.value
  };
};

onMounted(async () => {
  setValues({ textContent: content.value });
});
</script>
